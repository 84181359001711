.section img{
border-radius: 300px;
  width: 50%;
  height: 80%;
  object-fit:cover; 
  transition: all .25s ease;
}

.img-c{
  text-align: center;
  width: 100%;
  height:100%;
  border-top-left-radius:300px;
  border-top-right-radius:300px;
  display:block;
}
.toppest{
  margin-top: -10px;
  margin-left: 1%;
  color: #d46554;
}
