
canvas{
  margin-top: -18px;
  width: 47vh;

}
.title{
  color: white;
}

.nk-block-text p {
  color:white ;
}
